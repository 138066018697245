import React from 'react';

import { type AvailableProductsResponse, type ProviderResults } from '../../types';
import { type DataProvider } from './create-data-provider';
import { createAppSwitcherBffAvailableProductsProvider } from './app-switcher-bff-available-products-provider';

type RealProvider = DataProvider<AvailableProductsResponse>;
const {
	fetchMethod: fetchAppSwitcherBffAvailableProducts,
	ProviderComponent: DefaultBffDataProviderComponent,
} = createAppSwitcherBffAvailableProductsProvider() as RealProvider;

export const AvailableProductsProvider = ({
	children,
}: {
	children: (availableProducts: ProviderResults['availableProducts']) => React.ReactNode;
}) => <DefaultBffDataProviderComponent>{children}</DefaultBffDataProviderComponent>;

export const prefetchAvailableProducts = () => fetchAppSwitcherBffAvailableProducts({});
export const resetAvailableProducts = () => fetchAppSwitcherBffAvailableProducts.reset();
