import {
	createProviderWithCustomFetchData,
	type ExportedDataProvider,
} from './create-data-provider';
import { type AvailableProductsResponse } from '../../types';
import { fetchJsonWithNetworkRetries } from '../utils/fetch';
import { ExperienceMark } from '../utils/experience-tracker';
import { ASSETS_APP_KEY } from '../../constants';

export const APP_SWITCHER_BFF_AVAILABLE_PRODUCTS_ENDPOINT =
	'/gateway/api/app-switcher/api/available-products';

// TODO: remove this once Asset app becomes standalone app
const transformAppSwitcherBffResponse = (response: AvailableProductsResponse) => {
	if (response.assetsAsAnAppEnabled) {
		response.sites.forEach((site) => {
			if (
				site.availableProducts.some(
					(product) => product.productType === 'JIRA_SERVICE_DESK' && product.isJsmPremium,
				)
			) {
				site.availableProducts.push({ productType: ASSETS_APP_KEY });
			}
		});
	}
	return response;
};

export const appSwitcherBffAvailableProductsFetch =
	(url: string = APP_SWITCHER_BFF_AVAILABLE_PRODUCTS_ENDPOINT) =>
	async () => {
		let init: RequestInit | undefined;
		if (url === APP_SWITCHER_BFF_AVAILABLE_PRODUCTS_ENDPOINT) {
			init = {
				headers: {
					'Atl-App-Switcher-Private-API': 'true',
				},
			};
		}

		const response = await fetchJsonWithNetworkRetries<AvailableProductsResponse>(
			url,
			{
				intervalsMS: [50, 200, 1000],
			},
			init,
		);

		return transformAppSwitcherBffResponse(response);
	};

export const createAppSwitcherBffAvailableProductsProvider = (
	url: string = APP_SWITCHER_BFF_AVAILABLE_PRODUCTS_ENDPOINT,
): ExportedDataProvider<AvailableProductsResponse> => {
	return createProviderWithCustomFetchData<AvailableProductsResponse>(
		'appSwitcherBffAvailableProducts',
		ExperienceMark.API_APP_SWITCHER_BFF_AVAILABLE_PRODUCTS,
		appSwitcherBffAvailableProductsFetch(url),
	);
};
